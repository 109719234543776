import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './MesPatientFile.css'; // Importing the CSS file for styling

const groupMapping = {
  "SENSORY DATA POINTS": {
    "ANTHROPOMETRIC MEASUREMENTS": ["GEN-hei", "GEN-wai", "GEN-hip", "GEN-wei"],
    "VITAL SIGNS": ["GEN-tem", "GEN-bps", "GEN-bpd", "GEN-bpp", "GEN-sat", "GEN-oxp"],
    "BLOOD PANEL": ["GEN-bhg", "GEN-bgl", "CHO-dlt", "GEN-bua"],
    "URINE ANALYSIS": ["URI-uro", "URI-phx", "URI-blo", "URI-nit", "URI-bil", "URI-leu", "URI-ket", "URI-sg", "URI-glu", "URI-vtc", "URI-pro"],
    "PULMONARY FUNCTION": ["GEN-fev", "GEN-pef"],
    "CARDIOVASCULAR ASSESSMENT": ["GEN-ecp", "GEN-ecg"]
  },
  "MEDICAL HISTORY": {
    "INFECTIOUS DISEASES": ["HIV-kno", "HIV-tre", "HIV-pos", "HIV-tst"],
    "CARDIOVASCULAR HEALTH": ["GEN-hpt", "GEN-htt", "GEN-fhi"],
    "METABOLIC HEALTH": ["GEN-dbm", "GLU-fas"],
    "LIFESTYLE FACTORS": ["GEN-smo"]
  },
  "CURRENT SYMPTOMS AND RISK FACTORS": {
    "GENERAL SYMPTOMS": ["GEN-fat", "GEN-uwl", "GEN-foc"],
    "NEUROLOGICAL SYMPTOMS": ["GEN-hea"],
    "CARDIOVASCULAR SYMPTOMS": ["OED-per", "OED-pul", "GEN-sob", "GEN-ccp"],
    "RESPIRATORY SYMPTOMS": ["GEN-prc", "GEN-cbl", "GEN-pob"],
    "URINARY SYMPTOMS": ["GEN-urb"]
  },
  "MENTAL HEALTH (SRQ20)": {
    "DEPRESSIVE SYMPTOMS": ["SRQ20-09", "SRQ20-10", "SRQ20-11", "SRQ20-14", "SRQ20-15", "SRQ20-16", "SRQ20-17"],
    "ANXIETY SYMPTOMS": ["SRQ20-06", "SRQ20-04"],
    "SOMATIC SYMPTOMS": ["SRQ20-01", "SRQ20-02", "SRQ20-03", "SRQ20-05", "SRQ20-07", "SRQ20-19"],
    "COGNITIVE SYMPTOMS": ["SRQ20-08", "SRQ20-12"],
    "DECREASED ENERGY": ["SRQ20-13", "SRQ20-18", "SRQ20-20"]
  },
  "PREGNANCY": {
    "PREGNANCY": ["OBS-lnp", "OBS-ppr", "OBS-con", "OBS-cap", "OBS-npp", "OBS-npc", "OBS-prs", "OBS-lcb", "OBS-nsb", "OBS-nab", "OBS-npl", "OBS-nvd", "OBS-svb", "OBS-rbm", "OBS-blf", "OBS-buu", "OBS-hbu", "OBS-sys", "OBS-flh"]
  }
};

// Function to map keys to their corresponding group and sub-group
const getGroupForKey = (key) => {
  for (const group in groupMapping) {
    for (const subGroup in groupMapping[group]) {
      if (groupMapping[group][subGroup].includes(key)) {
        return { group, subGroup };
      }
    }
  }
  return null;
};

function MesPatientFile() {
  const { rid, pid } = useParams();
  const [testResults, setTestResults] = useState([]);
  const [patientDetails, setPatientDetails] = useState({});
  const [dataStatus, setDataStatus] = useState("Loading");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTestIndex, setActiveTestIndex] = useState(null);
  const [expandedSections, setExpandedSections] = useState({});
  const [activeSections, setActiveSections] = useState({});

  const base64Decode = (str) => {
    try {
      return atob(str);
    } catch (error) {
      console.error("Failed to decode base64:", error);
      setError("Invalid PID format.");
      return null;
    }
  };

  /* const fetchPatientDetails = async (decodedPid) => {
    try {
      const response = await fetch('/patientFileDetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idP: decodedPid, rid: rid }),
      });

      const data = await response.json();
      if (response.ok && Array.isArray(data.patientFile) && data.patientFile.length > 0) {
        const formattedResults = data.patientFile.map((test) => {
          return {
            date: test.date,
            inputs: JSON.parse(test.recordedInputs).scanResults || {},
            results: JSON.parse(test.recordedResults)["results "] || {},
          };
        });

        // Sort the tests by date (newest to oldest)
        const sortedResults = formattedResults.sort((a, b) => new Date(b.date) - new Date(a.date));

        // Extract the latest test for patient details
        const latestTest = sortedResults[0]?.inputs;
        if (latestTest) {
          setPatientDetails({
            id: latestTest["GEN-pid"]?.A || "N/A",
            gender: latestTest["GEN-gen"]?.A || "N/A",
            race: latestTest["GEN-rac"]?.A || "N/A",
            age: latestTest["GEN-age"]?.A || "N/A",
          });
        }

        setTestResults(sortedResults);
      } else {
        setError('No patient details found.');
      }
    } catch (err) {
      console.error("Error fetching patient details:", err);
      setError('An error occurred while fetching patient details.');
      setDataStatus("Error");
    } finally {
      setLoading(false);
      setDataStatus("Done");
    }
  }; */



  const fetchPatientDetails = async (decodedPid) => {
    try {
      const response = await fetch('/patientFileDetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idP: decodedPid, rid: rid }),
      });
  
      const data = await response.json();
      if (response.ok && Array.isArray(data.patientFile) && data.patientFile.length > 0) {
        const formattedResults = data.patientFile.map((test) => {
          let recordedInputs = {};
          let recordedResults = {};
  
          // Handle empty or invalid recordedInputs
          if (test.recordedInputs && test.recordedInputs.trim() !== "") {
            try {
              recordedInputs = JSON.parse(test.recordedInputs).scanResults || {};
            } catch (err) {
              console.error("Error parsing recordedInputs:", err);
            }
          }
  
          // Handle empty or invalid recordedResults
          if (test.recordedResults && test.recordedResults.trim() !== "") {
            try {
              recordedResults = JSON.parse(test.recordedResults)["results "] || {};
            } catch (err) {
              console.error("Error parsing recordedResults:", err);
            }
          }
  
          return {
            date: test.date,
            inputs: recordedInputs,
            results: recordedResults,
          };
        });
  
        // Sort the tests by date (newest to oldest)
        const sortedResults = formattedResults.sort((a, b) => new Date(b.date) - new Date(a.date));
  
        // Extract the latest test for patient details
        const latestTest = sortedResults[0]?.inputs;
        if (latestTest) {
          setPatientDetails({
            id: latestTest["GEN-pid"]?.A || "N/A",
            gender: latestTest["GEN-gen"]?.A || "N/A",
            race: latestTest["GEN-rac"]?.A || "N/A",
            age: latestTest["GEN-age"]?.A || "N/A",
          });
        }
  
        setTestResults(sortedResults);
      } else {
        setError('No patient details found.');
      }
    } catch (err) {
      console.error("Error fetching patient details:", err);
      setError('An error occurred while fetching patient details.');
      setDataStatus("Error");
    } finally {
      setLoading(false);
      setDataStatus("Done");
    }
  };
  

  useEffect(() => {
    const decodedPid = base64Decode(pid);
    if (decodedPid) {
      setDataStatus("Loading");
      fetchPatientDetails(decodedPid);
    } else {
      setLoading(false);
      setDataStatus("Error");
    }
  }, [pid]);

  const renderSensoryDataRow = (key, result) => (
    <div key={key} className="screen-grid">
      <span>{result?.scan}</span>
      <span><b>{result?.value !== null ? result?.value : '0'}</b></span>
      <span>{(result?.unit === 'txt' || result?.unit === 'Y or N') ? '' : result?.unit}</span>
    </div>
  );

  const renderMedicalHistoryRow = (key, result) => (
    <div key={key} className="question-grid">
      <span>{result?.Q}</span>
      <span><b>{result?.A !== null ? result?.A : 'N/A'}</b></span>
    </div>
  );

  const shouldDisplayPregnancy = (inputs) => {
    return groupMapping["PREGNANCY"]["PREGNANCY"].some((key) => key in inputs);
  };

   // Function to handle the section toggle for each test
   const toggleSection = (testIndex, section) => {
    setActiveSections((prevState) => ({
      ...prevState,
      [testIndex]: {
        ...prevState[testIndex],
        [section]: !prevState[testIndex]?.[section],
      },
    }));
  };

   // Helper function to get color class for the triage box
   const getTriageColorClass = (color) => {
    switch (color.toLowerCase()) {
      case "green":
        return "triage-green";
      case "red":
        return "triage-red";
      case "orange":
        return "triage-orange";
      default:
        return "";
    }
  };

  return (
    <div className='mes-patient-file-page'>
      <div className='mes-header'>
        <img src="http://medescreen.co.za/wp-content/uploads/2023/09/Medescreen-Logo-Green.png" alt="Logo" className="mes-logo" />
        <p className='tagline'><b>accessible</b> and <b>affordable</b> healthcare</p>
      </div>

      {dataStatus === "Loading" && (
        <div className='loading-screen'>
          <div className='loading-container'>
            <img src='http://medescreen.co.za/wp-content/uploads/2023/09/cropped-MedFavicon.png' className='loader' alt='loader' />
            <p className='loading-text'>loading patient data...</p>
          </div>
        </div>
      )}

{dataStatus === "Done" && (
        <div className='file-container'>

          <div className='desc-container'>
                <h3>General Information</h3>

                <div className='desc'>
                  <div className='desc-content'>
                    <p>The <b>Morbidity Score</b> is based on the sensor readings taken during the assessment and ranges from 0 to 100 where 0 indicates the best possible general health. If sensor readings or observations were skipped, the score will be less reliable but not necessarily higher.</p>
                  </div>
                </div>

                <div className='desc'>
                  <div className='desc-content'>
                    <p>The <b>Triage Colour</b> result combines the health score with specific issues and should be considered as additional information. For any colour score other than a green one, it is recommended that a medical professional be consulted, urgently if it is red.</p>
                  </div>
                  <div className='color-display'>
                    <div className='colors-red'>Red</div>
                    <div className='colors-orange'>Orange</div>
                    <div className='colors-green'>green</div>
                  </div>
                </div>
          </div>

          <div className="example-test-container">
            <h1>Triage Key Example</h1>
            <div className="example-test-summary">
              <h2 className="example-test-date">Date</h2>
              <span className="example-test-score">Morbidity Score</span>
              <div className={`example-triage-box ${getTriageColorClass('orange' || '')}`}>Triage Colour</div>
            </div>
          </div>


          <h1>Actual Patient Test Results</h1>
          <p className='instruction'>Click On Tests Below</p>
          
          {testResults.map((test, index) => (
            <div key={index} className="test-container">
               <div 
                  className={`test-summary ${index === activeTestIndex ? 'test-summary-active' : ''}`} 
                  onClick={() => setActiveTestIndex(index === activeTestIndex ? null : index)}
                >
                <h2 className="test-date">{new Date(test.date).toLocaleDateString('en-CA')}</h2>
                <span className="test-score">{`${test?.results?.score || 'N/A'}`}</span>
                <div className={`triage-box ${getTriageColorClass(test?.results?.colour || '')}`}></div>
                <span className={`test-dropdown ${index === activeTestIndex ? 'test-dropdown-active' : ''}`} >
                    <svg onClick={() => setActiveTestIndex(index === activeTestIndex ? null : index)} clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="m16.843 10.211c.108-.141.157-.3.157-.456 0-.389-.306-.755-.749-.755h-8.501c-.445 0-.75.367-.75.755 0 .157.05.316.159.457 1.203 1.554 3.252 4.199 4.258 5.498.142.184.36.29.592.29.23 0 .449-.107.591-.291 1.002-1.299 3.044-3.945 4.243-5.498z"/>
                     </svg>
                </span>
              </div>


                {/* If the test is active, display the sections */}
                {index === activeTestIndex && (
                  <div className="test-details">
                    {/* Patient Info Section */}
                    <div className="section-container">
                      <div
                        className={`section-header ${activeSections[index]?.patientInfo ? 'section-header-active' : ''}`}
                        onClick={() => toggleSection(index, 'patientInfo')}
                      >
                         <span className='section-header-text'>Patient Info</span>
                        {/* Add the SVG icon after the text */}
                        <span className={`dropdown-icon ${expandedSections[index]?.screeningInfo ? 'icon-expanded' : 'icon-collapsed'}`}>
                          <svg onClick={() => toggleSection(index, 'patientInfo')} clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m16.843 10.211c.108-.141.157-.3.157-.456 0-.389-.306-.755-.749-.755h-8.501c-.445 0-.75.367-.75.755 0 .157.05.316.159.457 1.203 1.554 3.252 4.199 4.258 5.498.142.184.36.29.592.29.23 0 .449-.107.591-.291 1.002-1.299 3.044-3.945 4.243-5.498z"/>
                          </svg>
                        </span>
                      </div>
                      {activeSections[index]?.patientInfo && (
                        <div className="section-content">
                          <div
                            className="section-header"
                            onClick={() => toggleSection(index, 'patientInfo')}
                          >
                            <span className='section-header-text'>Patient Info</span>
                            {/* Add the SVG icon after the text */}
                            <span className={`dropdown-icon ${expandedSections[index]?.screeningInfo ? 'icon-expanded' : 'icon-collapsed'}`}>
                            <svg onClick={() => toggleSection(index, 'patientInfo')} clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m16.843 13.789c.108.141.157.3.157.456 0 .389-.306.755-.749.755h-8.501c-.445 0-.75-.367-.75-.755 0-.157.05-.316.159-.457 1.203-1.554 3.252-4.199 4.258-5.498.142-.184.36-.29.592-.29.23 0 .449.107.591.291 1.002 1.299 3.044 3.945 4.243 5.498z"/></svg>
                            </span>
                          </div>
                          <div className='patient-details-info'>
                            <p><b>Screening:</b> {test.inputs["GEN-dep"]?.A || 'Self'}</p>
                            <p><b>ID:</b> {test.inputs["GEN-pid"]?.A || 'N/A'}</p>
                            <p><b>Gender:</b> {test.inputs["GEN-gen"]?.A || 'N/A'}</p>
                            <p><b>Race:</b> {test.inputs["GEN-rac"]?.A || 'N/A'}</p>
                            <p><b>Age:</b> {test.inputs["GEN-age"]?.A || 'N/A'}</p>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Results Section */}
                    <div className="section-container">
                      <div
                        className={`section-header ${activeSections[index]?.results ? 'section-header-active' : ''}`}
                        onClick={() => toggleSection(index, 'results')}
                      >
                         <span className='section-header-text'>Results</span>
                        {/* Add the SVG icon after the text */}
                        <span className={`dropdown-icon ${expandedSections[index]?.screeningInfo ? 'icon-expanded' : 'icon-collapsed'}`}>
                          <svg onClick={() => toggleSection(index, 'results')} clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m16.843 10.211c.108-.141.157-.3.157-.456 0-.389-.306-.755-.749-.755h-8.501c-.445 0-.75.367-.75.755 0 .157.05.316.159.457 1.203 1.554 3.252 4.199 4.258 5.498.142.184.36.29.592.29.23 0 .449-.107.591-.291 1.002-1.299 3.044-3.945 4.243-5.498z"/>
                          </svg>
                        </span>
                      </div>
                      {activeSections[index]?.results && (
                        <div className="section-content">
                           <div
                            className='section-header'
                            onClick={() => toggleSection(index, 'results')}
                          >
                            <span className='section-header-text'>Results</span>
                            {/* Add the SVG icon after the text */}
                            <span className={`dropdown-icon ${expandedSections[index]?.screeningInfo ? 'icon-expanded' : 'icon-collapsed'}`}>
                              <svg onClick={() => toggleSection(index, 'results')} clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m16.843 13.789c.108.141.157.3.157.456 0 .389-.306.755-.749.755h-8.501c-.445 0-.75-.367-.75-.755 0-.157.05-.316.159-.457 1.203-1.554 3.252-4.199 4.258-5.498.142-.184.36-.29.592-.29.23 0 .449.107.591.291 1.002 1.299 3.044 3.945 4.243 5.498z"/></svg>
                            </span>
                          </div>
                          <div className='results-content'>
                            <p><b>Score:</b> {test?.results?.score || 'N/A'}</p>

                            {/* Check if advice is an array and display each as a <p> */}
                            <p><b>Advice:</b></p>
                            {Array.isArray(test?.results?.Advice) && test.results.Advice.length > 0 ? (
                              <>
                                {test.results.Advice.map((item, index) => (
                                  <p key={index}>{item}</p> // Render each advice as a <p>
                                ))}
                              </>
                            ) : (
                              <p>N/A</p> // Fallback if advice is not available
                            )}

                            {/* Triage color box */}
                            <div className={`triage-box ${getTriageColorClass(test?.results?.colour || '')}`}></div>
                          </div>
                        </div>
                      )}
                    </div>

                   {/* Screening Info Section */}
                    <div className="section-container">
                      <div
                        className={`section-header ${activeSections[index]?.screeningInfo ? 'section-header-active' : ''}`}
                        onClick={() => toggleSection(index, 'screeningInfo')}
                      >
                        <span className='section-header-text'>Screening Info</span>
                        {/* Add the SVG icon after the text */}
                        <span className={`dropdown-icon ${expandedSections[index]?.screeningInfo ? 'icon-expanded' : 'icon-collapsed'}`}>
                          <svg onClick={() => toggleSection(index, 'results')} clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m16.843 10.211c.108-.141.157-.3.157-.456 0-.389-.306-.755-.749-.755h-8.501c-.445 0-.75.367-.75.755 0 .157.05.316.159.457 1.203 1.554 3.252 4.199 4.258 5.498.142.184.36.29.592.29.23 0 .449-.107.591-.291 1.002-1.299 3.044-3.945 4.243-5.498z"/>
                          </svg>
                        </span>
                      </div>
                      {activeSections[index]?.screeningInfo && (
                        <div className="section-content">
                          <div
                            className='section-header'
                            onClick={() => toggleSection(index, 'screeningInfo')}
                          >
                            <span className='section-header-text'>Screening Info</span>
                            {/* Add the SVG icon after the text */}
                            <span onClick={() => toggleSection(index, 'results')} className={`dropdown-icon ${expandedSections[index]?.screeningInfo ? 'icon-expanded' : 'icon-collapsed'}`}>
                            <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m16.843 13.789c.108.141.157.3.157.456 0 .389-.306.755-.749.755h-8.501c-.445 0-.75-.367-.75-.755 0-.157.05-.316.159-.457 1.203-1.554 3.252-4.199 4.258-5.498.142-.184.36-.29.592-.29.23 0 .449.107.591.291 1.002 1.299 3.044 3.945 4.243 5.498z"/></svg>
                            </span>
                          </div>
                          {Object.keys(groupMapping).map((group) => (
                            <div key={group}>
                              {/* Only display Pregnancy section if relevant */}
                              {group === "PREGNANCY" && !shouldDisplayPregnancy(test.inputs) ? null : (
                                <>
                                  <h4 className='group-name'>{group}</h4>
                                  {Object.keys(groupMapping[group]).map((subGroup) => (
                                    <div key={subGroup} className="test-section">
                                      <h5 className='subgroup-name'>{subGroup}</h5>
                                      {groupMapping[group][subGroup].map((key) => {
                                        const result = test.inputs[key];
                                        return group === "SENSORY DATA POINTS"
                                          ? renderSensoryDataRow(key, result)   // Render sensory data
                                          : renderMedicalHistoryRow(key, result);  // Render medical history and other groups
                                      })}
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                  </div>
                )}
            </div>
          ))}
          <div className='mes-file-footer'>
            <p>If you have any questions, you can contact us</p>
            <p>support@medescreen.co.za</p>
          </div>
        </div>
      )}
    </div>

  );
}

export default MesPatientFile;
