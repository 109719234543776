import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        // Initialize state from localStorage if available
        const savedAuthState = localStorage.getItem('isAuthenticated');
        return savedAuthState ? JSON.parse(savedAuthState) : false;
    });

    useEffect(() => {
        // Save authentication state to localStorage
        localStorage.setItem('isAuthenticated', JSON.stringify(isAuthenticated));
        console.log('AuthProvider: isAuthenticated state updated', isAuthenticated);
    }, [isAuthenticated]);

    const login = () => {
        console.log('AuthProvider: login function called');
        setIsAuthenticated(true);
    };

    const logout = () => {
        console.log('AuthProvider: logout function called');
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {console.log('AuthProvider: isAuthenticated', isAuthenticated)}
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
