import React, { useState, useEffect } from 'react';
import './Consent.css';

function ConsentPage({setIsFormDirty}){
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        idNumber: '',
        email: '',
        cell: '',
        primaryCell: '',
        secondaryCell: '',
        acceptTerms: false,
        consentFacialRecognition: false,
        consentInformationUse: false,
    });
    
    
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    
    useEffect(() => {
        // Enable submit button only if all consents are checked
        const { acceptTerms, consentFacialRecognition, consentInformationUse } = formData;
        setIsSubmitEnabled(acceptTerms && consentFacialRecognition && consentInformationUse);
    }, [formData]);
    
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
        
        setIsFormDirty(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsFormDirty(false);
        console.log('Submitted data:', formData);
        // Save patient information in local storage
        window.localStorage.setItem('patientInfo', JSON.stringify(formData));
        // Redirect to /voucher page
        window.location.href = '/voucher';
    };
    

    return (
        <div className='background'>
            <div className='background-filter'>
                <div className='consent-page'>
                    <img src="http://medescreen.co.za/wp-content/uploads/2023/09/Medescreen-Logo-Green.png" alt="Logo" className="consent-logo" />
                    <form onSubmit={handleSubmit}>
                        <div className="consent-page-container">
                            <div className="patient-form-container">

                                <h2>Patient Information</h2>

                                <input
                                    className='patient-form-input'
                                    type="text"
                                    name="idNumber"
                                    placeholder='ID Number'
                                    value={formData.idNumber}
                                    onChange={handleChange}
                                />

                                <input
                                    className='patient-form-input'
                                    type="text"
                                    name="name"
                                    placeholder='Name'
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />

                                <input
                                    className='patient-form-input'
                                    type="text"
                                    name="surname"
                                    placeholder='Surname'
                                    value={formData.surname}
                                    onChange={handleChange}
                                    required
                                />

                                <input
                                    className='patient-form-input'
                                    type="email"
                                    name="email"
                                    placeholder='Email'
                                    value={formData.email}
                                    onChange={handleChange}
                                />

                                {/* <input
                                    className='patient-form-input'
                                    type="tel"
                                    name="cell"
                                    placeholder='Cell Number'
                                    value={formData.cell}
                                    onChange={handleChange}
                                    required
                                /> */}

                                <input
                                    className='patient-form-input'
                                    type="tel"
                                    name="primaryCell"
                                    placeholder='Primary Cell Number'
                                    value={formData.primaryCell}
                                    onChange={handleChange}
                                    required
                                />

                                <input
                                    className='patient-form-input'
                                    type="tel"
                                    name="secondaryCell"
                                    placeholder='Secondary Cell Number (Optional)'
                                    value={formData.secondaryCell}
                                    onChange={handleChange}
                                />

                            </div>

                            <div className='terms-container'>
                                <h2>Terms and Conditions</h2>
                                <div className='terms'>
                                    <p>I hereby confirm that I have been informed about the nature, conduct, benefits, risks and implications involved in undertaking a Primary Health, HIV, TB and COVID-19 test/s (or treatment/s). I have also received and understood all the relevant information concerning the proposed tests (or treatments). I have had sufficient opportunity to ask questions and to consider whether I want to proceed with the tests (or treatments).</p>

                                    <p>I therefore freely and voluntarily agree to the Primary Health, HIV, TB and COVID-19 tests being performed, which includes the drawing of blood samples and tests on those samples. I agree that I will be informed of the results of the tests in person.</p>

                                    <p>I hereby agree that the results of the tests (or treatments) may be anonymously used for purposes of research and/or data-collection purposes, provided that such information is de-identified with sufficient safeguards.</p>

                                    <p>I also agree that my Biometric data be used for purposes of identifying me in order to improve the standard of service delivery to me, provided that such information is stored with sufficient safeguards.</p>

                                    <p>I know that I am, at any stage, free to withdraw my consent to undergo any of these tests (or treatments) and the use of my personal data for research and/or identifying me.</p>
                                </div>

                                <div className='checkbox-container'>
                                    <div className='check-box'>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="acceptTerms"
                                                checked={formData.acceptTerms}
                                                onChange={handleChange}
                                                required
                                            />
                                            Accept Terms and Conditions
                                        </label>
                                    </div>

                                    <div className='check-box'>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="consentFacialRecognition"
                                                checked={formData.consentFacialRecognition}
                                                onChange={handleChange}
                                                required
                                            />
                                            Consent for Facial Recognition
                                        </label>
                                    </div>

                                    <div className='check-box'>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="consentInformationUse"
                                                checked={formData.consentInformationUse}
                                                onChange={handleChange}
                                                required
                                            />
                                            Consent for Information Use
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <button type="submit" className='consent-button' disabled={!isSubmitEnabled} onClick={() => {setIsFormDirty(false);}} >Submit</button>

                        </div>
                    </form>
                </div >
            </div>
        </div>
    );
};

export default ConsentPage;
