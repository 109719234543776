import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './PatientFile.css'; // Importing the CSS file for styling

function PatientFile() {
  const { rid } = useParams(); // Extract the 'rid' from the URL

  useEffect(() => {
    // You can now use 'rid' to make API calls or process data
    console.log('RID:', rid);
  }, [rid]);

  const [pid, setPid] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false); // New state to track checkbox
  const [error, setError] = useState(''); // New state for error message
  const navigate = useNavigate();

  const base64Encode = (str) => {
    return btoa(str);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!termsAccepted) {
      setError('Please accept the terms and conditions before proceeding.');
      alert('Please accept the terms and conditions before proceeding.');
      return; // Prevent login if T&C not accepted
    }

    const encodedPid = base64Encode(pid);
    const response = await fetch('/patientFileConfirm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ pid: encodedPid, rid: rid }),
    });

    const data = await response.json();
    if (data.success) {
      const uniqueID = rid + "/" + encodedPid;
      console.log('Navigating to /mesPatientFile');
      navigate(`/mesPatientFile/${uniqueID}`);
    } else {
      alert('Login failed: ' + data.message);
    }
  };

  return (
    <div className='patient-file-background'>
      <div className='file-background-filter'>
        <div className='patient-file-page'>

          <div className='header'>
            <img src="http://medescreen.co.za/wp-content/uploads/2023/09/Med-e-screen-Logo-1.webp" alt="Logo" className="mes-logo" />
            <p><b>accessible</b> and <b>affordable</b> healthcare </p>
          </div>

          {/* <div className='desc-container'>
            <h3>General Info about the results...</h3>

            <div className='desc'>
              <div className='desc-content'>
                <p>The <b>Morbidity Score</b> is based on the sensor readings taken during the assessment and ranges from 0 to 100 where 0 indicates the best possible general health. If sensor readings or observations were skipped, the score will be less reliable but not necessarily higher.</p>
              </div>
            </div>

            <div className='desc'>
              <div className='desc-content'>
                <p>The <b>Colour Triage</b> result combines the health score with specific issues and should be considered as additional information. For any colour score other than a green one, it is recommended that a medical professional be consulted, urgently if it is red.</p>
              </div>
              <div className='color-display'>
                <div className='colors-red'>Red</div>
                <div className='colors-orange'>Orange</div>
                <div className='colors-green'>green</div>
              </div>
            </div>
          </div> */}

          <form className='login-form' onSubmit={handleSubmit}>
            <h3>To Access Your <b>Med-e-Screen File</b> Please Provide Your <b>ID Number</b></h3>


            {/* New checkbox for accepting T&Cs */}

            <input
              className='login-form-input'
              type="text"
              placeholder="ID number"
              required
              onChange={(e) => setPid(e.target.value)}
            />

            <div className='Terms'>
              In accordance with the Protection of Personal Information Act, 2013 (Act No. 4 of 2013), by using *Med-e-Screen* and sharing your personal health information, you acknowledge that *Med-e-Screen* complies with its obligations to ensure the lawful processing, protection, and confidentiality of your data. However, once you voluntarily disclose your information to third parties, *Med-e-Screen* cannot be held responsible for its use or protection.
              <br/>
              You agree to indemnify and hold harmless *Med-e-Screen* from any claims, liabilities, or damages arising from the sharing of your personal health information with external parties. By continuing to use our services, you confirm your acceptance of these terms.
            </div>
            
            <div className='patient-file-checkbox-container'>
              <input
                type="checkbox"
                id="terms"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
              <label htmlFor="terms">I accept the terms and conditions</label>
            </div>

            <button type="submit" className='login-btn'>Login</button>

            {/*
            {error && <p className="error-message">{error}</p>} 
            */}

          </form>

          <div className='footer'>
            <p>If you have any questions, you can contact us</p>
            <p>support@medescreen.co.za</p>
          </div>

        </div>
      </div>
    </div>
  );
}

export default PatientFile;
