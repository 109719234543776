import React, { useState, useContext } from 'react';
import './Login.css'; // Importing the CSS file for styling
import AuthContext from '../AuthContext';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const base64Encode = (str) => {
    return btoa(str);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const encodedEmail = base64Encode(email);
    const encodedPassword = base64Encode(password);
    const response = await fetch('/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: encodedEmail, password: encodedPassword }),
    });
    const data = await response.json();
    if (data.success) {
      console.log('Login successful, calling login function');
      
      login();
      // set email to local storage
      window.localStorage.setItem('nurseEmail', JSON.stringify(email));

      // Make the API hit to wake up the service
      console.log('Attempting to hit the wake-up API: https://phs.yimi.pw/status');
      fetch('https://phs.yimi.pw/status')
        .then(() => {
          console.log('Wake-up API hit successfully.');
        })
        .catch((error) => {
          console.error('Error hitting wake-up API:', error);
        });

      console.log('Navigating to /consent');
      navigate('/consent');

    } else {
      alert('Login failed: ' + data.message);
    }
  };

  return (
    <div className='background'>
      <div className='background-filter'>
        <div className="login-container">
          <div className="sideImg">
            <img src="http://medescreen.co.za/wp-content/uploads/2024/05/the-sollution.webp" className="solutionImg" alt="the solution" />
          </div>
          <div className="form-container">
            <img src="http://medescreen.co.za/wp-content/uploads/2023/09/Medescreen-Logo-Green.png" alt="Logo" className="logo" />
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button type="submit">Login</button>
            </form>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Login;
