import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import './App.css';
import './components/Login.css';
import Voucher from './components/Voucher';
import ConsentPage from './components/Consent';
import MedicalTestPage from './components/MedicalTest';
import ResultsPage from './components/Results';
import PatientFile from './components/patientFile';
import MesPatientFile from './components/mesPatientFile';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';

function App() {
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message = 'Your changes are not saved, are you sure you want to refresh?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/consent" element={<PrivateRoute><ConsentPage setIsFormDirty={setIsFormDirty} /></PrivateRoute>} />
          <Route path="/voucher" element={<PrivateRoute><Voucher setIsFormDirty={setIsFormDirty}/></PrivateRoute>} />
          <Route path="/medical_tests" element={<PrivateRoute><MedicalTestPage setIsFormDirty={setIsFormDirty} /></PrivateRoute>} />
          <Route path="/results" element={<PrivateRoute><ResultsPage setIsFormDirty={setIsFormDirty} /></PrivateRoute>} />
          <Route path="/uploadResults" element={<PrivateRoute><div>Upload Results Page</div></PrivateRoute>} />
          <Route path="/patientFile/:rid" element={<PatientFile />} />
          <Route path="/mesPatientFile/:rid/:pid" element={<MesPatientFile />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
