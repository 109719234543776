import React, { useEffect, useState } from 'react';
import './Results.css'; // Importing the CSS file for styling
import { useNavigate } from 'react-router-dom';

function ResultsPage() {
    const [results, setResults] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const savedResults = JSON.parse(window.localStorage.getItem('testResults'));
        setResults(savedResults);
    }, []);

    const handleLogOutClick = () => {
        window.localStorage.clear(); // Clear all localStorage data
        navigate('/'); // Navigate to the medical tests page
    };
    

    const handleSaveClick = () => {
        navigate('/consent');
    };

    return (
        <div className='background'>
            <div className='background-filter'>
                <div className="results-page">
                    <div className="results-image-container">
                        <img
                            className="results-image"
                            src="http://medescreen.co.za/wp-content/uploads/2023/09/Medescreen-Logo-Green.png"
                            alt="Medescreen Logo"
                        />
                    </div>
                    <h2>Thank you for using the Med-e-Screen App...</h2>
                    <h3>The results will be sent to the patient through SMS.</h3>
                    <div className='final-btn-container'>
                        <button className="new-test-btn" onClick={handleSaveClick}>New Test</button>
                        <button className="logout-btn" onClick={handleLogOutClick}>Logout</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResultsPage;
